@import "../../Style.scss";
.tips-wrapper {
  box-sizing: border-box;
  @include themed() {
    background: t("bg");
  }

  .tips-inner-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .logo {
      flex-shrink: 1;
      width: 100%;
      padding: 30px 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo-icon {
        width: 52px;
        height: 60px;
      }
      position: absolute;
      z-index: 9;
    }
    // .tips-content{
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   flex-direction: column;
    button {
      border-radius: 30.751px;
      width: 414px;
      margin: 20px auto;
      @include themed() {
        background: t("card-bg");
        &:hover {
          background-color: t("heading");
        }
      }
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      @include themed() {
        color: t("heading");
        &:hover {
          color: t("card-bg");
        }
      }
      text-align: center;
      font-family: "Kobe1.1 bold";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: lowercase;
      padding: 15px 10px;
    }
    .tips-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      position: absolute;
      padding: 35px;
    }
  }
}
.tips-stepper {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .circle-light {
    border-radius: 145px;
    background: radial-gradient(50% 50% at 50% 50%, #e4d2c4 0%, #b89589 100%);
    width: 145px;
    height: 145px;
  }
  .circle-dark {
    border-radius: 145px;
    background: radial-gradient(50% 50% at 50% 50%, #e4d2c4 0%, #2d100a 100%);
    width: 145px;
    height: 145px;
  }
  h1 {
    text-align: center;
    @include themed() {
      color: t("heading");
    }
    font-family: "Kobe1.1";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    @include themed() {
      color: t("heading");
    }
    text-align: center;
    font-family: "Kobe1.1";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 700px;
    // width: 80%;
  }
}

@media (max-width: 768px) {
  .tips-stepper {
    padding: 0;
    h1 {
      font-size: 42px;
    }
    p {
      font-size: 20px;
      width: 100%;
    }
    .circle-light,
    .circle-dark {
      width: 116px;
      height: 116px;
    }
  }
  .tips-wrapper .tips-inner-wrapper {
    // padding: 40px;
    .logo {
      padding: 40px;
      .logo-icon,
      .close-icon {
        width: 30px;
        height: 30px;
      }
    }
    button {
      font-size: 25px;
      width: 100%;
    }
  }
}
