@import "../../Style.scss";
.reset-password-wrapper {
  box-sizing: border-box;
  @include themed() {
    background: t("bg");
  }

  .reset-password-inner-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .logo {
      flex-shrink: 1;
      width: 100%;
      padding: 30px 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo-icon {
        width: 52px;
        height: 60px;
      }
      position: absolute;
      z-index: 9;
    }
    button {
      border-radius: 30.751px;
      width: 81px;
      height: 60px;
      margin: 20px auto;
      @include themed() {
        background: t("card-bg");
        &:hover {
          background-color: t("heading");
        }
      }
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      @include themed() {
        color: t("heading");
        &:hover {
          color: t("card-bg");
        }
      }
      text-align: center;
      font-family: "Kobe1.1 bold";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: lowercase;
      padding: 15px 10px;
    }
    .reset-password-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      position: absolute;
      padding: 35px;
    }
  }
}
.reset-password-inner-content {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 8px;
  h1 {
    text-align: center;
    @include themed() {
      color: t("heading");
    }
    font-family: "Kobe1.1";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
  }
  p {
    @include themed() {
      color: t("heading");
    }
    text-align: center;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 673px;
    margin-top: 0px;
    margin-bottom: 0px;
   

  }
}

@media (max-width: 768px) {
  .reset-password-inner-content {
    padding: 0;
    h1 {
      font-size: 42px;
    }
    p {
      font-size: 20px;
      width: 350px;
      height: 91px;
      
    }
    
  }
  .reset-password-wrapper .reset-password-inner-wrapper {
    .logo {
      padding: 40px;
      .logo-icon,
      .close-icon {
        width: 30px;
        height: 30px;
      }
    }
    button {
      font-size: 25px;
      width: 25%;
    }
  }
}
