@import "../../Style.scss";
.contact-us-wrapper {
  box-sizing: border-box;
  @include themed() {
    background: t("bg");
  }

  .contactus-form-wrapper {
    height: 100vh;
    overflow-y: auto;
    position: relative;
    a {
      color: #000;
    }

    .logo {
      flex-shrink: 1;
      width: 100%;
      padding: 50px 100px 30px 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 9;
      .logo-icon {
        width: 52px;
        height: 60px;
      }
    }

    .auth-form {
      flex-grow: 1;
      box-sizing: border-box;
      padding: 125px 40px 40px;
      width: 100%;
      max-width: 100%;
      min-height: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      .title {
        @include themed() {
          color: t("heading");
        }
        text-align: center;
        font-family: "Kobe1.1 bold";
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .description {
        @include themed() {
          color: t("heading");
        }
        text-align: center;
        font-family: "Inter";
        font-size: 21px;
        width: 689px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .form {
        width: 580px;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 20px;
        font-family: "Kobe1.1";

        .input-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 10px;
        }
        .field-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .error-message {
          color: #dc3545;
          margin: 10px 0 0 20px;
          line-height: 1;
          text-transform: lowercase;
        }

        .MuiTextField-root {
          width: 100%;
          background-color: transparent;
          border-radius: 0px;
          outline: none;
          @include themed() {
            color: t("grey-text");
          }
          font-family: "Kobe1.1";
          font-size: 27.848px;
          font-style: normal;
          font-weight: 400;
        }

        .MuiOutlinedInput-notchedOutline {
          border-radius: 30px;
          @include themed() {
            border: 1px solid t("heading");
          }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          @include themed() {
            border: 2px solid t("heading");
          }
          border-radius: 30px;
        }
        .MuiOutlinedInput-input,
        .MuiInputLabel-outlined {
          @include themed() {
            color: t("grey-text");
          }
          font-family: "Kobe1.1";
          font-size: 27px;
          font-style: normal;
          font-weight: 400;
          height: 56px;
          padding: 0 10px;
        }
        legend {
          font-size: 21px;
        }
        .MuiOutlinedInput-input {
          padding: 0 17px;
          &#outlined-basic {
            padding: 0 5px;
          }
        }

        .MuiInputLabel-outlined {
          transform: translate(14px, 13px) scale(1);
          &.MuiInputLabel-shrink {
            transform: translate(10px, -7px) scale(0.73);
          }
        }

        .MuiFormLabel-root.Mui-focused {
          @include themed() {
            color: t("heading");
          }
        }

        .PrivateNotchedOutline-legendLabelled-3 > span {
          font-size: 18px;
        }
        .MuiButton-contained {
          border-radius: 30.751px;
          width: 414px;

          @include themed() {
            background: t("button");
          }
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          @include themed() {
            color: t("text");
          }
          text-align: center;
          font-family: "Kobe1.1 bold";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .contactus-form-wrapper {
      .logo {
        padding: 40px;
        .logo-icon,
        .close-icon {
          width: 30px;
          height: 30px;
        }
      }
      .auth-form {
        // margin-top: 30px;
        // padding: 20px 40px;
        width: 100%;
        .title {
          font-size: 32.42px;
        }

        .description {
          width: 100%;
          font-size: 15px;
          margin: 30px auto;
        }
        .form {
          width: 100%;

          .error-message {
            color: #dc3545;
            margin: 5px 0 0 20px;
            line-height: 0;
            text-transform: lowercase;
            font-size: 12px;
          }

          .MuiButton-contained {
            font-size: 25px;
            width: 100%;
          }

          .MuiOutlinedInput-input,
          .MuiInputLabel-outlined {
            font-size: 20px;
          }
          .MuiInputLabel-outlined {
            transform: translate(14px, 13px) scale(1);
            &.MuiInputLabel-shrink {
              transform: translate(14px, -7px) scale(0.73);
            }
          }
          .mobile-responsive-input {
            .MuiOutlinedInput-root,
            .MuiOutlinedInput-notchedOutline {
              height: 50px;
            }

            // .MuiInputLabel-outlined {
            //   line-height: 0px;
            // }
            // .MuiFormLabel-root.Mui-focused {
            //   line-height: unset;
            // }
          }
          legend {
            font-size: 15px;
          }
        }
      }
    }
  }
  // @media (min-width: 768px) and (min-height: 640px) {
  //   .signin-form-wrapper .auth-form .title {
  //     font-size: 1.8rem;
  //   }
  //   .signin-form-wrapper .auth-form .description {
  //     font-size: 18px;
  //   }
  // }
}
