@import "../../Style.scss";
.privacy-container {
  background: $rose-beige-light;
  padding: 40px 100px;
  h1 {
    color: $burgundy;
    font-family: "Kobe1.1 bold";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 50px 0px 50px 13px;
  }
  ol {
    li {
      font-weight: 700;
      margin: 20px 0px;
      font-size: 20px;

      h3 {
        color: $burgundy;
        font-family: "Inter";
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      }
      p {
        font-family: "Inter";
        font-weight: 400;
        font-size: 20px;
        margin: 0px;
        margin-left: -18px;
        a {
          color: $burgundy;
          font-family: "Inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
  }
  ul {
    padding: 0px;
    list-style-type: disc;
    li {
      font-weight: 400;
      font-size: 20px;
      margin: 0px;
    }
    li::marker {
      font-size: 20px;
      font-weight: bold;
      @include themed() {
        color: t("text");
      }
    }
  }
}
@media (max-width: 768px) {
  .privacy-container {
    padding: 20px;
    h1 {
      font-size: 40px;
      margin: 10px auto;
    }
    ol {
      padding-inline-start: 20px;
      li {
        font-size: 14.678px;
        h3 {
          font-size: 14.678px;
        }
        p {
          font-size: 13.21px;
          a {
            font-size: 14.678px;
          }
        }
      }
      ul {
        padding-inline-start: 8px;
        li {
          font-size: 13.21px;
          line-height: 18px;
        }
      }
    }
  }
}
