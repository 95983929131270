@import "../../Style.scss";
.sign-in-google {
  height: fit-content;
  h1 {
    @include themed() {
      color: t("heading");
    }
    font-family: "Kobe1.1 bold";
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
