@import "../../Style.scss";
.about-us {
  padding: 40px 100px;
  position: relative;

  .center-circle-dark {
    border-radius: 936px;
    background: radial-gradient(50% 50% at 50% 50%, #9f766d 0%, #2d100a 100%);
    width: 936px;
    height: 926px;
    position: absolute;
    z-index: 0;
    right: 8%;
  }
  .center-circle-light {
    border-radius: 936px;
    background: radial-gradient(50% 50% at 50% 50%, #a1756c 0%, #e4d2c4 99.99%);
    width: 936px;
    height: 926px;
    position: absolute;
    z-index: 0;
    right: 8%;
  }
  .circle-bg {
    // background: url("../../assets/images/mainGradient.svg") no-repeat;
    width: 100%;
    height: 926px;
    position: relative;
    z-index: 9;
  }
  .head-section {
    width: 100%;
    z-index: 10;
    h1 {
      @include themed() {
        color: t("heading");
      }
      font-family: "Kobe1.1 bold";
      font-size: 75.192px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      column-gap: 20px;
      img {
        width: 200px;
      }
    }
    h3 {
      @include themed() {
        color: t("heading");
      }
      font-family: "Kobe1.1 bold";
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 300px;
      margin-bottom: 100px;
    }
    .bold-p {
      @include themed() {
        color: t("heading");
      }
      font-family: "Kobe1.1";
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .light-p {
      @include themed() {
        color: t("heading");
      }
      font-family: "Kobe1.1";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .card-section {
    position: relative;
    width: 100%;
    margin-top: 100px;
    z-index: 10;
    .card-maincontainer {
      width: 100%;
      margin: auto;
      border-radius: 20px;
      overflow-x: hidden;
      @include themed() {
        background: t("card-bg");
      }
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      .card-title-dark {
        position: relative;
        height: 70px;
        .card-dark-bg {
          position: absolute;
          border-radius: 20px;
          background: linear-gradient(90deg, #2d100a 0%, #dac4b4 100%);
          padding: 10px 20px 10px 40px;
          animation: gradientDarkAnimation 5s ease forwards infinite;
          height: 100%;
        }
        h1 {
          @include themed() {
            color: t("text-light");
          }
          font-family: "Kobe1.1";
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0px;
          position: absolute;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 45px;
        }
      }
      .card-title-light {
        position: relative;
        height: 70px;
        border-radius: 20px;
        overflow: hidden;
        .card-light-bg {
          position: absolute;
          border-radius: 20px;
          background: linear-gradient(90deg, #dac4b4 0%, #2d100a 100%);
          padding: 10px 20px 10px 40px;
          animation: gradientLightAnimation 5s ease forwards infinite;
          height: 100%;
          width: 100%;
        }
        h1 {
          @include themed() {
            color: t("text-light");
          }
          font-family: "Kobe1.1";
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0px;
          position: absolute;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 45px;
        }
      }
      .card-detail {
        padding: 40px;

        p {
          @include themed() {
            color: t("heading");
          }
          margin: 0px;
          font-family: "Inter";
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .join-content {
    position: relative;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px auto;
    .join-bg-section {
      position: absolute;
      padding: 20px;
      background: url("../../assets/images/rectangleCircle.svg") no-repeat
        center center;
      text-align: center;
      min-height: 210px;
      width: 100%;
      top: 0;
      background-size: contain;
    }
    .join-section {
      position: absolute;
      z-index: 9;
      text-align: center;

      button {
        border-radius: 22.5px;
        @include themed() {
          background: t("button");
        }
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        @include themed() {
          color: t("text");
        }
        text-align: center;
        text-transform: lowercase;
        font-family: "Kobe1.1";
        font-size: 30px;
        min-width: 180px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
@media (max-width: 768px) {
  .about-us {
    padding: 0px;
    .center-circle-light {
      background: radial-gradient(
        65% 47% at 98% 50%,
        #a1756c 0%,
        #e4d2c4 99.99%
      );
      height: 565px;
      width: 100%;
      border-radius: 0px;
      top: 10px;
      right: 0px;
    }
    .center-circle-dark {
      background: radial-gradient(
        65% 47% at 98% 50%,
        #9f766d -16%,
        #2d100a 100%
      );
      height: 565px;
      width: 100%;
      border-radius: 0px;
      top: 10px;
      right: 0px;
    }
    .circle-bg {
      // background: url("../../assets/images/halfGradient.svg") no-repeat;
      padding: 0px;
      width: 100%;
      .head-section {
        width: 100%;
        padding: 20px;
        height: 926px;
        h1 {
          font-size: 57px;
          flex-direction: column;
          align-items: flex-start;
          img {
            width: 150px;
          }
        }
        h3 {
          font-size: 30px;
          width: 200px;
          margin-bottom: 150px;
          margin-top: 50px;
        }
        p {
          font-size: 18px;
        }
      }
    }
    .card-section {
      margin: 100px auto;
      .card-maincontainer {
        width: 90%;
        .card-title-dark h1,
        .card-title-light h1 {
          font-size: 25px;
        }
        .card-detail p {
          font-size: 13px;
        }
      }
    }
    .join-content {
      // margin: 0;
      height: 120px;
      .join-bg-section {
        padding: 0;
        min-height: 120px;
      }
      .join-section {
        button {
          font-size: 17px;
          min-width: 110px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .join-content {
    .join-bg-section {
      background-size: cover !important;
    }
  }
}

@keyframes gradientDarkAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes gradientLightAnimation {
  0% {
    // width: 0;
    left: 100%;
  }
  100% {
    // width: 0;
    left: 0;
  }
}
