@import "../../Style.scss";
.welcome-content {
  box-sizing: border-box;
  @include themed() {
    background: t("bg");
  }
  background: #2d100a;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .animation-content {
    display: flex;
    gap: 100px;
    justify-content: center;
    width: 100%;
    .common-box {
      display: flex;
      gap: 20px;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      .center-content {
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .circle {
        width: 80px;
        height: 80px;
        border-radius: 50px;
      }
      .rectangle {
        height: 200px;
        width: 65px;
        background-color: #2d100a;
      }
      .rectangle-dark {
        height: 200px;
        width: 65px;
        background-color: #dac4b4;
      }
    }
  }
}

html:not(.no-js) .common-box-right[data-aos="fade-right"] {
  transform: translate3d(-100%, 0, 0);
}

html:not(.no-js) .common-box-left[data-aos="fade-left"] {
  transform: translate3d(100%, 0, 0);
}
