@import "../../Style.scss";
.dashboard {
  padding: 50px 100px;
  display: flex;
  height: calc(100vh - 92px);

  .left-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    // min-height: 75vh;
    gap: 50px;
    height: 100%;
    flex: 0 0 30%;
    max-width: 30%;
    h1 {
      @include themed() {
        color: t("heading");
      }
      font-family: "Kobe1.1";
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    p {
      @include themed() {
        color: t("grey-text");
      }
      font-family: "Inter";
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    // .black-text {
    //   @include themed() {
    //     color: t("text");
    //   }
    // }
    .chat-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 15px;
      flex-shrink: 0;
      @include themed() {
        color: t("heading");
        &:hover {
          color: t("card-bg");
        }
      }
      border-radius: 30.751px;
      @include themed() {
        background: t("card-bg");
        &:hover {
          background-color: t("heading");
        }
      }
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 5px 20px;
      text-align: right;
      font-family: "Kobe1.1";
      font-size: 23.301px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: lowercase;

      .plus-sign {
        width: 20px;
        height: 3px;
        margin-right: 10px;
        @include themed() {
          background-color: t("heading");
        }
        position: relative;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 3px;
          height: 20px;
          @include themed() {
            background-color: t("heading");
          }
        }
        &::before {
          left: 9px;
          top: -9px;
        }
        &::after {
          left: 9px;
          top: -9px;
        }
      }

      &:hover {
        .plus-sign {
          @include themed() {
            background-color: t("card-bg");
          }
          &::before {
            @include themed() {
              background-color: t("card-bg");
            }
          }
          &::after {
            @include themed() {
              background-color: t("card-bg");
            }
          }
        }
      }
    }
    .history-btn {
      border-radius: 0;
      @include themed() {
        color: t("grey-text");
      }

      background: transparent;
      box-shadow: none;
      font-family: "Inter";
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding: 15px 0px;
    }
    .history-btn:focus {
      @include themed() {
        color: t("heading");
      }
    }

    .circle-light {
      border-radius: 145px;
      background: radial-gradient(50% 50% at 50% 50%, #e4d2c4 0%, #b89589 100%);
      width: 145px;
      height: 145px;
    }
    .circle-dark {
      border-radius: 145px;
      background: radial-gradient(50% 50% at 50% 50%, #e4d2c4 0%, #2d100a 100%);
      width: 145px;
      height: 145px;
    }
  }
  .right-section {
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
    @include themed() {
      background: t("card-bg");
    }
    // flex: 1;
    // min-height: 75vh;
    height: 100%;

    h5 {
      @include themed() {
        color: t("grey-text");
      }
      font-family: "Inter";
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p {
      @include themed() {
        color: t("heading");
      }
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: flex-start;

      gap: 10px;
      img {
        width: 30px;
      }
      span {
        cursor: pointer;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.footer-dashboard {
  display: none;
}
@media (max-width: 768px) {
  .footer-dashboard {
    display: block;
  }
  .dashboard {
    padding: 40px;
    flex-direction: column;
    height: auto;
    .left-section {
      height: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      h1 {
        font-size: 37.162px;
      }
      p {
        font-size: 20px;
      }
      .chat-btn {
        font-size: 23.3px;
      }
      .history-btn {
        font-size: 20px;
      }
    }
    .right-section {
      height: 412px;
      h5 {
        font-size: 21px;
      }
      p {
        font-size: 20px;
      }
    }
  }
}
