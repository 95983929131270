$burgundy: #2d100a;
$rose-beige-buttons: #dac4b4;
$rose-beige: #e4d2c4;
$text-light: #f6f3ea;
$grey-text: #7e7e7e;
$rose-beige-light: #fff4eb;
$burgundy-lighter: #4b2922;

$themes: (
  light: (
    bg: $rose-beige,
    text: $burgundy,
    heading: $burgundy,
    button: $rose-beige-buttons,
    text-light: $text-light,
    grey-text: $grey-text,
    rose-beige-light: $rose-beige-light,
    burgundy-lighter: $burgundy-lighter,
    footer-text: #a2766d,
    card-bg: $rose-beige-buttons,
    btn-bg: $burgundy,
    btn-text: $rose-beige-buttons,
  ),
  dark: (
    bg: $burgundy,
    text: $burgundy,
    heading: $rose-beige,
    button: $rose-beige-buttons,
    text-light: $text-light,
    grey-text: $grey-text,
    footer-text: #636363,
    card-bg: $burgundy-lighter,
    burgundy-lighter: $burgundy-lighter,
    btn-bg: $rose-beige-buttons,
    btn-text: $burgundy,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
