.navigate-room-wrapper {
  .usgae-card-wrapper {
    .usgae-card {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 50px;
    }
  }
  @media (max-width: 768px) {
    .usgae-card-wrapper {
      .usgae-card {
        padding: 80px 30px 30px;
        .usage-disclaimer-popup {
          width: 100%;
          margin: auto;
          padding: 10px;
          ol {
            padding-inline-start: 20px;
          }
          ol > li {
            margin: 0px;
          }
          ol li h3,
          ol li::marker,
          ol ul li {
            font-size: 13.21px;
          }
          ol ul {
            padding-inline-start: 0px;
          }
          .agreement-wrapper {
            font-size: 23.721px;
          }
        }
      }
    }
  }
}
