@import "../../Style.scss";
.successful {
  box-sizing: border-box;
  @include themed() {
    background: t("bg");
  }
  background: #2d100a;
  height: 100vh;
  position: relative;
  .successful-content {
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 100%;
    justify-content: end;
  }
  .success-msg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 0 100px;
  }
  .animation-content {
    display: flex;
    gap: 100px;
    justify-content: center;
    width: 100%;
    .common-box {
      display: flex;
      gap: 20px;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      .center-content {
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .circle {
        width: 80px;
        height: 80px;
        border-radius: 50px;
      }
      .rectangle {
        height: 200px;
        width: 65px;
        background-color: #2d100a;
      }
      .rectangle-dark {
        height: 200px;
        width: 65px;
        background-color: #dac4b4;
      }
    }
  }
  h1 {
    @include themed() {
      color: t("heading");
    }
    text-align: center;

    font-family: " Kobe1.1";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
@media (max-height: 500px) {
  .successful-content {
    gap: 50px !important;
  }
  .rectangle,
  .rectangle-dark {
    height: 100px !important;
  }
}
