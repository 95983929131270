.visualizerContainer {
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 20px;

  border-radius: 100px;
  @media  (max-width: 800px) {
  max-width: calc(100vw - 30px);
  }
}

.visualizer {
  display: flex;
  align-items: flex-end;
  height: 40px;
  gap: 2px;
  overflow: hidden;
  position: relative;
}

.bar {
  width: 2px;
  min-width: 2px;
  flex-shrink: 0;
  margin: auto 0;
  z-index: 1;
  transition: height 0.1s
}
