.profile-wrapper {
  box-sizing: border-box;

  .profile-form-wrapper {
    background: #fff;
    display: flex;
    align-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;

    a {
      color: #000;
    }

    .logo {
      padding-top: 2rem;
      flex-shrink: 1;

      .logo-icon {
        height: 20px;
        width: auto;
      }
    }

    .auth-form {
      flex-grow: 1;
      box-sizing: border-box;
      padding: 2rem 2rem 6rem;
      width: 100%;
      max-width: 700px;
      min-height: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1rem;
        line-height: 1.6;
      }

      .description {
        text-align: center;
        line-height: 1.6;
      }

      .form {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 20px;
        .textarea {
          width: 100%;
          background-color: transparent;
          border-radius: 0px;
          outline: none;
          border-color: 0, 0, 0;
          height: 100px;
          .MuiOutlinedInput-root {
            height: 100%;
          }
        }
        .textarea:focus {
          border-width: 2px;
          border-color: #000;
        }
        .MuiTextField-root {
          width: 100%;
          background-color: transparent;
          border-radius: 0px;
          outline: none;
          border-color: 0, 0, 0;
        }

        .MuiOutlinedInput-root {
          border-radius: 0px;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-width: 2px;
          border-color: #000;
        }

        .MuiInputLabel-outlined {
          font-size: 15px;
        }

        .MuiFormLabel-root.Mui-focused {
          color: #000;
        }
        .buttonContainer {
          width: 100%;
        }
        .cancelButton {
          background-color: #fff;
          color: #000;
          border: 1px solid #000;
        }
      }

      .additional-actions {
        text-align: center;
        margin-top: 3rem;
      }

      .profile-form-wrapper a {
        color: #000;
      }
    }
  }

  @media (min-width: 768px) and (min-height: 640px) {
    .profile-form-wrapper .auth-form .title {
      font-size: 1.8rem;
    }
    .profile-form-wrapper .auth-form .description {
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    .profile-form-wrapper .auth-form .MuiFormGroup-root.MuiFormGroup-row {
      flex-direction: column;
    }
  }
}
