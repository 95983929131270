@import "../../Style.scss";
.sign-up-wrapper {
  box-sizing: border-box;
  @include themed() {
    background: t("bg");
  }

  .signup-form-wrapper {
    display: flex;
    // align-content: center;
    // align-items: center;
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    position: relative;

    a {
      color: #000;
    }

    .logo {
      flex-shrink: 1;
      width: 100%;
      padding: 50px 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 9;
      .logo-icon {
        width: 52px;
        height: 60px;
      }
    }

    .auth-form {
      flex-grow: 1;
      box-sizing: border-box;
      padding: 100px 0;
      width: 100%;
      max-width: 440px;
      min-height: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;
      // padding-bottom: 50px;
      position: absolute;
      .title {
        @include themed() {
          color: t("heading");
        }
        text-align: center;
        font-family: "Kobe1.1 bold";
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .description {
        text-align: center;
        line-height: 1.6;
      }

      .form {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 20px;

        .field-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .error-message {
          color: #dc3545;
          margin: 10px 0 0 20px;
          line-height: 1;
          text-transform: lowercase;
        }

        .MuiTextField-root {
          width: 100%;
          background-color: transparent;
          border-radius: 0px;
          outline: none;
          @include themed() {
            color: t("grey-text");
          }
          font-family: "Kobe1.1";
          font-size: 27.848px;
          font-style: normal;
          font-weight: 400;
        }

        .MuiOutlinedInput-notchedOutline {
          border-radius: 30px;
          @include themed() {
            border: 1px solid t("heading");
          }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          @include themed() {
            border: 2px solid t("heading");
          }
          border-radius: 30px;
        }
        .MuiOutlinedInput-input,
        .MuiInputLabel-outlined {
          @include themed() {
            color: t("grey-text");
          }
          font-family: "Kobe1.1";
          font-size: 27px;
          font-style: normal;
          font-weight: 400;
          height: 56px;
          padding: 0 10px;
        }
        legend {
          font-size: 21px;
        }
        .MuiInputLabel-outlined {
          transform: translate(14px, 13px) scale(1);
          &.MuiInputLabel-shrink {
            transform: translate(10px, -7px) scale(0.73);
          }
        }

        .MuiFormLabel-root.Mui-focused {
          @include themed() {
            color: t("heading");
          }
        }
        .PrivateNotchedOutline-legendLabelled-3 > span {
          font-size: 18px;
        }
        .MuiButton-contained {
          border-radius: 30.751px;
          @include themed() {
            background: t("button");
          }
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          @include themed() {
            color: t("text");
          }
          text-align: center;
          font-family: "Kobe1.1 bold";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .other-signup-wrapper {
        p {
          @include themed() {
            color: t("grey-text");
          }
          text-align: center;
          font-family: "Kobe1.1";
          font-size: 27.848px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 0 15px 0;
        }
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          width: 100%;
          button {
            cursor: pointer;
            border-radius: 24.858px;
            @include themed() {
              border: 1px solid t("heading");
              color: t("heading");
            }
            font-family: "Inter";
            font-size: 23.804px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 5px 15px;
            @include themed() {
              background: t("bg");
            }
            display: flex;
            align-items: center;
            height: 50px;
            gap: 10px;

            img {
              width: 30px;
            }
          }
        }
      }
      .agreement-wrapper,
      .agreement-wrapper-mobile,
      .agreement-wrapper-mobile span,
      .additional-actions {
        @include themed() {
          color: t("grey-text");
        }
        font-family: "Kobe1.1";
        font-size: 27.848px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
        text-align: center;
      }
      .agreement-wrapper {
        @include themed() {
          color: t("heading");
        }
        .error-message {
          color: #dc3545;
          margin: 10px 0 0 20px;
          line-height: 1;
          text-transform: lowercase;
          font-size: 20px;
        }
        p {
          display: inline;
        }
        span {
          @include themed() {
            color: t("heading");
          }
          text-decoration: underline;
          cursor: pointer;
        }
        .MuiSvgIcon-root {
          width: 30px;
          height: 30px;
          @include themed() {
            color: t("heading");
          }
        }
      }
      .agreement-wrapper-mobile {
        display: none;
      }
      .additional-actions {
        .MuiButton-contained {
          border-radius: 30.751px;
          @include themed() {
            background: t("button");
          }
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          @include themed() {
            color: t("text");
          }
          text-align: center;
          font-family: "Kobe1.1 bold";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 20px auto 0px auto;
        }
      }

      .google-wrapper {
        text-align: center;
        padding-top: 1.5rem;
        img {
          cursor: pointer;
          width: 50px;
          height: 50px;
        }
      }
    }
    .usgae-card {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 50px;
    }
  }
  @media (max-width: 768px) {
    .signup-form-wrapper {
      .logo {
        padding: 40px;
        .logo-icon,
        .close-icon {
          width: 30px;
          height: 30px;
        }
      }

      .auth-form {
        padding: 70px 30px;
        .title {
          font-size: 42px;
        }
        .form {
          .MuiButton-contained {
            font-size: 25px;
          }
          .MuiOutlinedInput-input,
          .MuiInputLabel-outlined {
            font-size: 20px;
          }
          legend {
            font-size: 15px;
          }
        }
        .agreement-wrapper,
        .additional-actions,
        .agreement-wrapper-mobile,
        .agreement-wrapper-mobile span,
        .other-signup-wrapper p {
          font-size: 23.721px;
        }
        .agreement-wrapper {
          display: none;
        }
        .agreement-wrapper-mobile {
          display: block;
          .error-message {
            color: #dc3545;
            margin: 10px 0 0 20px;
            line-height: 1;
            text-transform: lowercase;
            font-size: 20px;
          }
          p {
            display: inline;
          }
          span {
            text-decoration: underline;
            cursor: pointer;
          }
          .MuiSvgIcon-root {
            width: 30px;
            height: 30px;
            @include themed() {
              color: t("heading");
            }
          }
        }
      }
      .usgae-card {
        padding: 80px 30px 30px;
        .usage-disclaimer-popup {
          width: 100%;
          margin: auto;
          padding: 10px;
          ol {
            padding-inline-start: 20px;
          }
          ol > li {
            margin: 0px;
          }
          ol li h3,
          ol li::marker,
          ol ul li {
            font-size: 13.21px;
          }
          ol ul {
            padding-inline-start: 0px;
          }
          .agreement-wrapper {
            font-size: 23.721px;
          }
        }
      }
    }
  }

  @media (min-width: 768px) and (min-height: 640px) {
    // .signup-form-wrapper .auth-form .title {
    //   font-size: 50px;
    // }
    .signup-form-wrapper .auth-form .description {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    .auth-form {
      padding: 70px 10px !important;
    }
  }
}
